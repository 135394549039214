define("shared/helpers/linkify", ["exports", "linkify-string"], function (_exports, _linkifyString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkify = linkify;
  _exports.default = void 0;
  const MAX_LENGTH = 1000;

  function linkify(params) {
    let content = params[0] || '';

    if (content.length > MAX_LENGTH) {
      return content;
    }

    content = Ember.Handlebars.Utils.escapeExpression(content);
    content = (0, _linkifyString.default)(content, {
      attributes: {
        rel: 'nofollow noreferrer',
        target: '_blank'
      },
      defaultProtocol: 'https'
    });
    return Ember.String.htmlSafe(content);
  }

  var _default = Ember.Helper.helper(linkify);

  _exports.default = _default;
});